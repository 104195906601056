#content {
  display: flex;
  flex-direction: col;
  width: 100vw;
  height: 100vh;
}

main {
  flex-grow: 1;
}

.panel-right {
  width: 400px;
  height: 100%;
}

aside {
  position: fixed;
  z-index: 9;
  padding: 32px;
  width: 400px;
}

aside.left {
  left: 0;
}

aside.right {
  right: 0;
}

aside.bottom {
  bottom: 0;
}

.space-y-1 > * + * {
  margin-top: 1rem;
}

main {
  flex-grow: 1;
}
