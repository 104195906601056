body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.no-padding .p-accordion-content {
  padding: 0 !important;
}

.p-accordion {
  max-height: 100%;
}

.p-accordion-content,
.p-toggleable-content,
.p-accordion-tab {
  max-height: 100%;
}

.recharts-legend-item {
  margin-left: 10px !important;
}
