.circle {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  margin-right: 8px;
}

.green {
  background-color: #00cc6a;
}

.red {
  background-color: #fa4f40;
}

.yellow {
  background-color: #ffb900;
}

.blue {
  background-color: #0063b1;
}

li {
  display: flex;
  align-items: center;
  margin-left: -16px;
}
